import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styles from './Navbar.module.css'
import logo from '../../Assets/Logo.png'
const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false)

    const toggleMenu = () => {
        setIsOpen(!isOpen)
    }

    return (
        <>
            <div className={styles.header}>
                <div className={styles.container}>
                    <div className={styles.navbar}>
                        <Link to="/" className={styles.logo}><img src={logo} alt='BJS Healthcare Logo' /></Link>
                        <div className={styles.desktopMenu}>
                            <Link to="/" className={styles.navLink}>Home</Link>
                            <Link to="/About" className={styles.navLink}>About</Link>
                            <Link to="/Manufacturing" className={styles.navLink}>Manufacturing</Link>
                            <Link to='/Products' className={styles.navLink}>Products</Link>
                            <Link to='/Contact' className={styles.navLink}>Contact Us</Link>
                           
                            {/* <Link to='/Contact'><button className={styles.button}>Enquire now</button></Link> */}
                        </div>
                        <button className={styles.mobileMenuButton} onClick={toggleMenu}>
                            <span className={styles.srOnly}>Open main menu</span>
                            <svg className={styles.menuIcon} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>

            <div className={`${styles.mobileMenu} ${isOpen ? styles.open : ''}`}>
                <div className={styles.mobileMenuContent}>
                    <div className={styles.mobileMenuHeader}>
                        <h2 className={styles.mobileMenuTitle}>Menu</h2>
                        <button className={styles.closeButton} onClick={toggleMenu}>
                            <span className={styles.srOnly}>Close main menu</span>
                            <svg className={styles.closeIcon} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>

                    <nav className={styles.mobileNav}>
                        <Link to="/" className={styles.mobileNavLink} onClick={toggleMenu}>Home</Link>
                        <Link to="/about" className={styles.mobileNavLink} onClick={toggleMenu}>About</Link>
                        <Link to="/Manufacturing" className={styles.mobileNavLink} onClick={toggleMenu}>Manufacturing</Link>
                        <Link to="/Products" className={styles.mobileNavLink} onClick={toggleMenu}>Products</Link>
                        <Link to="/contact" className={styles.mobileNavLink} onClick={toggleMenu}>Contact</Link>
                        <button className={`${styles.button} ${styles.mobileButton}`} onClick={toggleMenu}>Enquire now</button>
                    </nav>
                    
                </div>
            </div>
        </>
    )
}

export default Navbar
