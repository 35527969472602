import React, { useState, useEffect, useRef } from 'react'
import styles from './Manufacturing.module.css'


import manufacturing_hero from '../../Assets/About/about_hero.mp4'
import our_focus from '../../Assets/About/Quality.jpg'
import quality_controll from '../../Assets/About/about_quality_controll.jpg'
import research from '../../Assets/About/Practices.jpg'
import { Helmet } from 'react-helmet'


// Product Range Images 
import painkiller from '../../Assets/Manufacturing/Product_range/painkiller.png'
import softgel from '../../Assets/Manufacturing/Product_range/Softgel.png'
import Syrup from '../../Assets/Manufacturing/Product_range/syrup.png'
import nasal_drop from '../../Assets/Manufacturing/Product_range/nasal_drop.png'
import anti_diabetic from '../../Assets/Manufacturing/Product_range/Anti_diabetic.png'
import cardiac from '../../Assets/Manufacturing/Product_range/Cardiac.png'
import gynaecology from '../../Assets/Manufacturing/Product_range/Gynaecology.png'
import paediatric from '../../Assets/Manufacturing/Product_range/Paediatric.png'
import orthopedic from '../../Assets/Manufacturing/Product_range/Orthopedic.png'
import gastrology from '../../Assets/Manufacturing/Product_range/Gastrology.png'


const Manufacturing = () => {
  const [activeProcess, setActiveProcess] = useState(0)
  const [isVideoLoaded, setIsVideoLoaded] = useState(false)
  const videoRef = useRef(null)
  const observerRef = useRef(null)

  const processes = [
    { title: 'Research', icon: '🔬' },
    { title: 'Development', icon: '🧪' },
    { title: 'Testing', icon: '🧬' },
    { title: 'Production', icon: '⚙️' },
    { title: 'Quality Control', icon: '✅' },
    { title: 'Packaging', icon: '📦' }
  ]

  const therapeuticAreas = [
    'Anti Diabetic', 'Cardiac Range', 'Gynaecology', 'PCOD',
    'Pregnancy', 'Paediatric', 'Gastrology', 'Dermatology'
  ]

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1
    }

    observerRef.current = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add(styles.animate)
        }
      })
    }, options)

    document.querySelectorAll(`.${styles.animateOnScroll}`).forEach(el => {
      observerRef.current.observe(el)
    })

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect()
      }
    }
  }, [])

  const handleVideoLoad = () => {
    setIsVideoLoaded(true)
  }

  return (
    <>
      <Helmet>
      
        {/* Primary Meta Tags */}
        <title>Manufacturing | BJS Healthcare | Pharmaceutical Excellence</title>
        <meta
          name="description"
          content="Explore BJS Healthcare's state-of-the-art manufacturing facilities in Baddi and Roorkee. We specialize in high-quality pharmaceutical formulations, including pain management, soft gels, liquids, and nasal drops, with a strong focus on research, development, and quality control."
        />
        <meta name="keywords" content="BJS Healthcare Manufacturing, Pharmaceutical Manufacturing, Quality Control, Research and Development, Pain Management, Soft Gels, Liquids, Nasal Drops, Therapeutic Areas, Baddi, Roorkee, Pharmaceutical Company" />
        <meta name="author" content="BJS Healthcare Pvt Ltd" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />

        {/* Open Graph / Facebook */}
        <meta property="og:title" content="Manufacturing | BJS Healthcare | Pharmaceutical Excellence" />
        <meta
          property="og:description"
          content="Discover BJS Healthcare's cutting-edge manufacturing capabilities, delivering high-quality pharmaceutical solutions with a focus on innovation and sustainability."
        />
        <meta property="og:image" content="https://example.com/path-to-manufacturing-hero-image.jpg" />
        <meta property="og:url" content="https://bjshealthcare.com/Manufacturing" />
        <meta property="og:type" content="website" />

        {/* Twitter */}
        <meta name="twitter:title" content="Manufacturing | BJS Healthcare | Pharmaceutical Excellence" />
        <meta
          name="twitter:description"
          content="Learn about BJS Healthcare's manufacturing facilities and capabilities, producing top-quality pharmaceutical formulations trusted worldwide."
        />
        <meta name="twitter:image" content="https://example.com/path-to-manufacturing-hero-image.jpg" />
        <meta name="twitter:card" content="summary_large_image" />

        {/* Additional Meta Tags */}
        <meta property="og:site_name" content="BJS Healthcare Pvt Ltd" />
        <link rel="canonical" href="https://bjshealthcare.com/Manufacturing" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Organization",
            "name": "BJS Healthcare Pvt Ltd",
            "url": "https://bjshealthcare.in",
            "logo": "https://bjshealthcare.in",
            "description": "BJS Healthcare Pvt Ltd is an ISO 9001-2015 and WHO-GMP certified pharmaceutical manufacturer, offering over 1200 high-quality products.",
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "5",
              "reviewCount": "9521"
            },
            "review": {
              "@type": "Review",
              "reviewRating": {
                "@type": "Rating",
                "ratingValue": "5",
                "bestRating": "5"
              },
              "author": {
                "@type": "Person",
                "name": "Satisfied Customer"
              }
            }
          })}
        </script>
      </Helmet>
      <div className={styles.manufacturing}>
        <section className={styles.hero}>
          <video
            ref={videoRef}
            autoPlay
            loop
            muted
            onLoadedData={handleVideoLoad}
            className={`${styles.backgroundVideo} ${isVideoLoaded ? styles.videoLoaded : ''}`}
          >
            <source src='https://yeskondomedia.com/Videos/about_hero.mp4' type="video/mp4" />
          </video>
          <div className={`${styles.heroContent} ${isVideoLoaded ? styles.contentLoaded : ''}`}>
            <h1>Cutting-Edge Pharmaceutical Manufacturing</h1>
            <p>Pioneering Health Solutions Since 2010</p>
          </div>
        </section>

        <section className={`${styles.intro} ${styles.animateOnScroll}`}>
          <div className={styles.container}>
            <h2 className={styles.heading}>Third Party Manufacturing Pharma Company</h2>
            <p>BJS Healthcare PVT. LTD. is a fastest emerging well-known company, committed to delivering quality products since 2010. Since 2016, we have been extensively engaged in the manufacturing of pharmaceutical formulations. We have a state-of-the-art manufacturing facility in Baddi (Himachal Pradesh) with major expansion plans to cater to the international market.</p>
          </div>
        </section>

        <section className={`${styles.process} ${styles.animateOnScroll}`}>
          <div className={styles.container}>
            <h2 className={styles.heading}>Our Manufacturing Process</h2>
            <div className={styles.processVisualizer}>
              {processes.map((process, index) => (
                <div
                  key={index}
                  className={`${styles.processStep} ${index === activeProcess ? styles.active : ''}`}
                  onClick={() => setActiveProcess(index)}
                >
                  <div className={styles.processIcon}>{process.icon}</div>
                  <div className={styles.processTitle}>{process.title}</div>
                </div>
              ))}
            </div>
            <div className={styles.processDescription}>
              <h3>{processes[activeProcess].title}</h3>
              <p>Our {processes[activeProcess].title.toLowerCase()} process ensures the highest quality and efficiency in pharmaceutical manufacturing.</p>
            </div>
          </div>
        </section>

        <section className={`${styles.focus} ${styles.animateOnScroll}`}>
          <div className={styles.container}>
            <h2 className={styles.heading}>Our Focus</h2>
            <div className={styles.focusContent}>
              <div className={styles.focusText}>
                <p>Our main focus is to produce cost-effective, high-quality, and secure solutions for our customers. To accomplish this goal, we utilize the best technology, methods, and personnel. Our innovative approaches, ideas, and outstanding client assistance at every step are the intrigue behind the prestige of our brand.</p>
              </div>
              <div className={styles.focusImage}>
                <img src={our_focus} alt="Our Focus" />
              </div>
            </div>
          </div>
        </section>

        <section className={`${styles.qualityControl} ${styles.animateOnScroll}`}>
          <div className={styles.container}>
            <h2 className={styles.heading}>Quality Control</h2>
            <div className={styles.qualityContent}>
              <div className={styles.qualityImage}>
                <img src={quality_controll} alt="Quality Control" />
              </div>
              <div className={styles.qualityText}>
                <p>We own an extreme infrastructure that is set up with the most advanced concepts for convenient delivery of our developed quality drugs. The quality control laboratory, packaging, and shipping units are devised to regulate the goals and aspirations, keeping in mind the standards of the Industry.</p>
              </div>
            </div>
          </div>
        </section>

        <section className={`${styles.researchDevelopment} ${styles.animateOnScroll}`}>
          <div className={styles.container}>
            <h2 className={styles.heading}>Research and Development</h2>
            <div className={styles.rdContent}>
              <div className={styles.rdText}>
                <p>Our infrastructure also comprises a research and development center where we carry out different researches to ensure that we deliver optimum quality products to our clients. This commitment to innovation keeps us at the forefront of the pharmaceutical industry.</p>
              </div>
              <div className={styles.rdImage}>
                <img src={research} alt="Research and Development" />
              </div>
            </div>
          </div>
        </section>

        <section className={`${styles.capabilities} ${styles.animateOnScroll}`}>
          <div className={styles.container}>
            <h2 className={styles.heading}>Our Manufacturing Capabilities</h2>
            <p>The manufacturing unit has been established at Baddi (Distt. Solan) Himachal Pradesh by the name of BJS Healthcare PVT LTD. This unit has three sections to manufacture products in various forms:</p>
            <div className={styles.capabilitiesGrid}>
              <div className={styles.capabilityCard}>
               
                <ul>
                  <li className={styles.product_range_holder}>
                    <img src={painkiller} alt='BJS Haelthcare Products Manufacturing range' />
                    <p>Painkiller</p>
                  </li>

                  <li className={styles.product_range_holder}>
                    <img src={softgel} alt='BJS Haelthcare Products Manufacturing range' />
                    <p>Softgel</p>
                  </li>


                  <li className={styles.product_range_holder}>
                    <img src={Syrup} alt='BJS Haelthcare Products Manufacturing range' />
                    <p>Syrup</p>
                  </li>


                  <li className={styles.product_range_holder}>
                    <img src={nasal_drop} alt='BJS Haelthcare Products Manufacturing range' />
                    <p>Nasal drop</p>
                  </li>


                  <li className={styles.product_range_holder}>
                    <img src={anti_diabetic} alt='BJS Haelthcare Products Manufacturing range' />
                    <p>Anti diabetic</p>
                  </li>


                  <li className={styles.product_range_holder}>
                    <img src={cardiac} alt='BJS Haelthcare Products Manufacturing range' />
                    <p>Cardiac</p>
                  </li>


                  <li className={styles.product_range_holder}>
                    <img src={gynaecology} alt='BJS Haelthcare Products Manufacturing range' />
                    <p>Gynaecology</p>
                  </li>

                  <li className={styles.product_range_holder}>
                    <img src={paediatric} alt='BJS Haelthcare Products Manufacturing range' />
                    <p>Paediatric</p>
                  </li>

                  <li className={styles.product_range_holder}>
                    <img src={orthopedic} alt='BJS Haelthcare Products Manufacturing range' />
                    <p>Orthopedic</p>
                  </li>

                  <li className={styles.product_range_holder}>
                    <img src={gastrology} alt='BJS Haelthcare Products Manufacturing range' />
                    <p>Gastrology</p>
                  </li>


                </ul>
              </div>

            </div>
          </div>
        </section>

        <section className={`${styles.contact} ${styles.animateOnScroll}`}>
          <div className={styles.container}>
            <h2 className={styles.heading}>Contact Information</h2>
            <div className={styles.contactInfo}>
              <div className={styles.contactCard}>
                <h3>BJS Healthcare</h3>
                <p>Office No:- 206 VTP trade Park Katraj Hadpasar road Undri Pune</p>
                <p>Phone: +91 9730100676</p>
                <p>Email: info@bjshealthcare.com</p>
                <h3>Manufacturing Units</h3>
                <p>Unit 1:- Baddi Himachal Pradesh </p>
                <p>Unit 2:- Roorkee Uttarakhand</p>
              </div>
              <div className={styles.contactForm}>
                <h3>Get in Touch</h3>
                <form>
                  <input type="text" placeholder="Your Name" required />
                  <input type="email" placeholder="Your Email" required />
                  <textarea placeholder="Your Message" required></textarea>
                  <button type="submit">Send Message</button>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default Manufacturing