import React, { useState, useEffect } from 'react'
import styles from './Hero.module.css'
import herovid from '../../Assets/Home/Hero/Hero.mp4'
import { Link } from 'react-router-dom';

const Hero = () => {
  const [contentIndex, setContentIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  const heroContent = [
    {
      title: "Third Party Manufacturing",
      description: "BJS HEALTHCARE PVT LTD. is the fastest-emerging, well-known ISO 9001-2015, WHO-GMP certified. We are driven by a team of highly committed, dedicated, and experienced pharmaceutical professionals. Our commitment to excellence sets us apart in the dynamic landscape of the pharmaceutical industry."
    },
    {
      title: "Quality Assurance",
      description: "At BJS Healthcare, we prioritize quality in every step of our manufacturing process. Our state-of-the-art facilities and rigorous quality control measures ensure that we deliver products that meet the highest industry standards."
    },
    {
      title: "Innovation in Pharmaceuticals",
      description: "We are at the forefront of pharmaceutical innovation, constantly researching and developing new formulations to address evolving healthcare needs. Our cutting-edge technologies and expert team drive us towards groundbreaking solutions."
    },
    {
      title: "Global Reach, Local Impact",
      description: "With a vision to improve global health, we extend our services worldwide while maintaining a strong local presence. Our diverse product range caters to various therapeutic areas, making quality healthcare accessible to all."
    }
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setIsAnimating(true);
      setTimeout(() => {
        setContentIndex((prevIndex) => (prevIndex + 1) % heroContent.length);
        setIsAnimating(false);
      }, 500); // Half of the transition duration
    }, 5000); // Change content every 5 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={styles.hero}>
      <div className={styles.hero_video}>
        <video src="https://yeskondomedia.com/Videos/Hero.mp4" autoPlay loop muted playsInline className={styles.videoBackground}></video>
        <div className={styles.overlay}></div>
      </div>
      <div className={styles.hero_content}>
        <h1 className={`${styles.title} ${isAnimating ? styles.fadeOut : styles.fadeIn}`} key={`title-${contentIndex}`}>
          {heroContent[contentIndex].title}
        </h1>
        <p className={`${styles.description} ${isAnimating ? styles.fadeOut : styles.fadeIn}`} key={`desc-${contentIndex}`}>
          {heroContent[contentIndex].description}
        </p>
        <Link to='/Contact'><button className={styles.call_now}>Contact Us</button></Link>
      </div>
    </div>
  )
}

export default Hero