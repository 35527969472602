import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Navbar from './Components/Navbar/Navbar';
import Footer from './Components/Footer/Footer';
import ScrollToTop from './Components/ScrollToTop';
import Home from './Components/Home/Home';
import About from './Components/About/About';
import Manufacturing from './Components/Manufacturing/Manufacturing';
import Products from './Components/Products/Products';
import Contact from './Components/Contact/Contact';
import routes from './routes'; // Import the routes file

const App = () => {
  const components = {
    '/': Home,
    '/About': About,
    '/Manufacturing': Manufacturing,
    '/Products': Products,
    '/Contact': Contact,
  };

  return (
    <>
      <BrowserRouter basename="/">
        <Navbar />
        <ScrollToTop />
        <Routes>
          {routes.map((route) => (
            <Route key={route.path} path={route.path} element={React.createElement(components[route.path])} />
          ))}
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
};

export default App;
