import React, { useState, useEffect, useRef } from 'react'
import styles from './About.module.css'
import about_hero from '../../Assets/About/about_hero.mp4'
import about_facility from '../../Assets/About/about_facility.jpg'
import { FaCheckCircle, FaLightbulb, FaGlobe, FaLeaf } from 'react-icons/fa';


import about_quality_controll from '../../Assets/About/about_quality_controll.jpg'
import mission from '../../Assets/About/mission.jpg'
import vision from '../../Assets/About/Quality.jpg'
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const About = () => {
    const [isVideoLoaded, setIsVideoLoaded] = useState(false)
    const [activeTab, setActiveTab] = useState('mission')
    const videoRef = useRef(null)
    const observerRef = useRef(null)

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.1
        }

        observerRef.current = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add(styles.animate)
                }
            })
        }, options)

        document.querySelectorAll(`.${styles.animateOnScroll}`).forEach(el => {
            observerRef.current.observe(el)
        })

        return () => {
            if (observerRef.current) {
                observerRef.current.disconnect()
            }
        }
    }, [])

    const handleVideoLoad = () => {
        setIsVideoLoaded(true)
    }

    return (
        <>
            <Helmet>
        
                {/* Primary Meta Tags */}
                <title>About Us | BJS Healthcare | Pharmaceutical Manufacturing</title>
                <meta
                    name="description"
                    content="Learn about BJS Healthcare, a globally recognized pharmaceutical manufacturer committed to quality, innovation, and sustainability. Explore our mission, vision, values, and quality standards."
                />
                <meta name="keywords" content="BJS Healthcare About Us, Pharmaceutical Manufacturing, WHO-GMP Certified, ISO 9001-2015, Quality Commitment, Global Reach, Innovation in Pharmaceuticals" />
                <meta name="author" content="BJS Healthcare Pvt Ltd" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />

                {/* Open Graph / Facebook */}
                <meta property="og:title" content="About Us | BJS Healthcare | Pharmaceutical Manufacturing" />
                <meta
                    property="og:description"
                    content="Discover the story of BJS Healthcare, a leader in pharmaceutical manufacturing with a focus on quality, innovation, and sustainability. Learn about our journey and global impact."
                />
                <meta property="og:image" content="https://example.com/path-to-about-hero-image.jpg" />
                <meta property="og:url" content="https://bjshealthcare.com/about" />
                <meta property="og:type" content="website" />

                {/* Twitter */}
                <meta name="twitter:title" content="About Us | BJS Healthcare | Pharmaceutical Manufacturing" />
                <meta
                    name="twitter:description"
                    content="Learn about BJS Healthcare, a WHO-GMP and ISO-certified pharmaceutical manufacturer. Explore our mission, vision, and commitment to quality."
                />
                <meta name="twitter:image" content="https://example.com/path-to-about-hero-image.jpg" />
                <meta name="twitter:card" content="summary_large_image" />

                {/* Additional Meta Tags */}
                <meta property="og:site_name" content="BJS Healthcare Pvt Ltd" />
                <link rel="canonical" href="https://bjshealthcare.com/about" />
                <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Organization",
            "name": "BJS Healthcare Pvt Ltd",
            "url": "https://bjshealthcare.in",
            "logo": "https://bjshealthcare.in/",
            "description": "BJS Healthcare Pvt Ltd is an ISO 9001-2015 and WHO-GMP certified pharmaceutical manufacturer, offering over 1200 high-quality products.",
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "5",
              "reviewCount": "9521"
            },
            "review": {
              "@type": "Review",
              "reviewRating": {
                "@type": "Rating",
                "ratingValue": "5",
                "bestRating": "5"
              },
              "author": {
                "@type": "Person",
                "name": "Satisfied Customer"
              }
            }
          })}
        </script>
            </Helmet>

            <div className={styles.about}>
                <section className={styles.hero}>
                    <video
                        ref={videoRef}
                        autoPlay
                        loop
                        muted
                        onLoadedData={handleVideoLoad}
                        className={`${styles.backgroundVideo} ${isVideoLoaded ? styles.videoLoaded : ''}`}
                    >
                        <source src='https://yeskondomedia.com/Videos/about_hero.mp4' type="video/mp4" />
                    </video>
                    <div className={`${styles.heroContent} ${isVideoLoaded ? styles.contentLoaded : ''}`}>
                        <h1>Pioneering Healthcare Solutions</h1>
                        <p>BJS Healthcare: Innovating for a Healthier Tomorrow</p>
                        <a href="#about" className={styles.ctaButton}>Discover Our Story</a>
                    </div>
                </section>

                <section id="about" className={`${styles.intro} ${styles.animateOnScroll}`}>
                    <div className={styles.container}>
                        <h2 className={styles.heading}>Revolutionizing Pharmaceutical Manufacturing</h2>
                        <div className={styles.introContent}>
                            <div className={styles.introText}>
                                <p>Since 2010, BJS Healthcare PVT. LTD. has been at the forefront of pharmaceutical innovation. Our state-of-the-art facility in Baddi, Himachal Pradesh, stands as a testament to our commitment to quality and excellence.</p>
                                <ul className={styles.achievements}>
                                    <li>
                                        <span className={styles.achievementNumber}>10+</span>
                                        <span className={styles.achievementText}>Years of Excellence</span>
                                    </li>
                                    <li>
                                        <span className={styles.achievementNumber}>1200+</span>
                                        <span className={styles.achievementText}>Products Manufactured</span>
                                    </li>
                                    <li>
                                        <span className={styles.achievementNumber}>50+</span>
                                        <span className={styles.achievementText}>Countries Served</span>
                                    </li>
                                </ul>
                            </div>
                            <div className={styles.introImage}>
                                <img src={about_facility} alt="BJS Healthcare Facility" />
                            </div>
                        </div>
                    </div>
                </section>

                <section className={`${styles.whyChooseUs} ${styles.animateOnScroll}`}>
                    <div className={styles.container}>
                        <h2 className={styles.heading}>Why Choose BJS Healthcare</h2>
                        <div className={styles.reasons}>
                            {[
                                { icon: <FaCheckCircle />, title: 'Uncompromising Quality', description: 'Our rigorous quality control ensures every product meets the highest standards.' },
                                { icon: <FaLightbulb />, title: 'Cutting-edge Innovation', description: 'We continuously invest in R&D to stay ahead of industry trends.' },
                                { icon: <FaGlobe />, title: 'Global Reach', description: 'Our products are trusted by healthcare professionals worldwide.' },
                                { icon: <FaLeaf />, title: 'Sustainable Practices', description: 'We\'re committed to environmentally responsible manufacturing.' }
                            ].map((reason, index) => (
                                <div key={index} className={styles.reason}>
                                    <div className={styles.reasonIcon}>
                                        {reason.icon}
                                    </div>
                                    <h3>{reason.title}</h3>
                                    <p>{reason.description}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>

                <section className={`${styles.missionVision} ${styles.animateOnScroll}`}>
                    <div className={styles.container}>
                        <div className={styles.tabs}>
                            <button
                                className={`${styles.tabButton} ${activeTab === 'mission' ? styles.active : ''}`}
                                onClick={() => setActiveTab('mission')}
                            >
                                Our Mission
                            </button>
                            <button
                                className={`${styles.tabButton} ${activeTab === 'vision' ? styles.active : ''}`}
                                onClick={() => setActiveTab('vision')}
                            >
                                Our Vision
                            </button>
                            <button
                                className={`${styles.tabButton} ${activeTab === 'values' ? styles.active : ''}`}
                                onClick={() => setActiveTab('values')}
                            >
                                Our Values
                            </button>
                        </div>
                        <div className={styles.tabContent}>
                            {activeTab === 'mission' && (
                                <div className={styles.missionContent}>
                                    <h3>Our Mission</h3>
                                    <p>To be a leading global pharmaceutical company, dedicated to improving the quality of life by providing innovative and affordable healthcare solutions.</p>
                                    <img src={mission} alt="Our Mission" />
                                </div>
                            )}
                            {activeTab === 'vision' && (
                                <div className={styles.visionContent}>
                                    <h3>Our Vision</h3>
                                    <p>To be recognized worldwide for our commitment to excellence in pharmaceutical manufacturing and our positive impact on global health.</p>
                                    <img src={vision} alt="Our Vision" />
                                </div>
                            )}
                            {activeTab === 'values' && (
                                <div className={styles.valuesContent}>
                                    <h3>Our Values</h3>
                                    <ul>
                                        <li>Integrity</li>
                                        <li>Excellence</li>
                                        <li>Innovation</li>
                                        <li>Collaboration</li>
                                        <li>Sustainability</li>
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>
                </section>

                <section className={`${styles.qualityCommitment} ${styles.animateOnScroll}`}>
                    <div className={styles.container}>
                        <h2 className={styles.heading}>Our Commitment to Quality</h2>
                        <div className={styles.qualityContent}>
                            <div className={styles.qualityText}>
                                <p>At BJS Healthcare, quality is not just a practice; it's our culture. We adhere to the most stringent quality control measures, ensuring that every product that leaves our facility meets the highest standards of safety and efficacy.</p>
                                <ul className={styles.certifications}>
                                    <li>WHO-GMP Certified</li>
                                    <li>ISO 9001:2015</li>
                                    <li>EU-GMP Compliance</li>
                                </ul>
                            </div>
                            <div className={styles.qualityImage}>
                                <img src={about_quality_controll} alt="Quality Control" />
                            </div>
                        </div>
                    </div>
                </section>

                <section className={`${styles.timeline} ${styles.animateOnScroll}`}>
                    <div className={styles.container}>
                        <h2 className={styles.heading}>Our Journey of Excellence</h2>
                        <div className={styles.timelineContent}>
                            {[
                                { year: 2010, event: 'BJS Healthcare founded' },
                                { year: 2013, event: 'Launched first product line' },
                                { year: 2016, event: 'Expanded manufacturing capabilities' },
                                { year: 2019, event: 'Achieved WHO-GMP certification' },
                                { year: 2022, event: 'Entered international markets' }
                            ].map((item, index) => (
                                <div key={index} className={styles.timelineItem}>
                                    <div className={styles.timelineMarker}></div>
                                    <div className={styles.timelineInfo}>
                                        <h3>{item.year}</h3>
                                        <p>{item.event}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>

                <section className={`${styles.cta} ${styles.animateOnScroll}`}>
                    <div className={styles.container}>
                        <h2 className={styles.heading}>Ready to Partner with BJS Healthcare?</h2>
                        <p>Join us in our mission to revolutionize healthcare through innovation and quality.</p>
                        <Link to="/contact" className={styles.ctaButton}>Get in Touch</Link>
                    </div>
                </section>
            </div>
        </>
    )
}

export default About