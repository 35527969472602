import React, { useState, useEffect } from 'react';
import styles from './Products.module.css';
import Productdata from './Data';

import about_hero from '../../Assets/About/about_hero.mp4';
import { Helmet } from 'react-helmet';

const Products = () => {
  const [selectedCategory, setSelectedCategory] = useState('All'); // Default to "All"
  const [filteredProducts, setFilteredProducts] = useState(Productdata); // Display all products initially
  const [searchTerm, setSearchTerm] = useState(''); // No search term initially

  const categories = ['All', 'Betalactam', 'Nonbetalactam', 'Softgel Capsules', 'Liquids/Syrup', 'Injectable'];

  useEffect(() => {
    // Filter whenever the category or search term changes
    const filterProducts = () => {
      let filtered = Productdata;

      // 1. Filter by category (if not "All")
      if (selectedCategory !== 'All') {
        filtered = filtered.filter(
          (product) => product.category.toLowerCase() === selectedCategory.toLowerCase()
        );
      }

      // 2. Filter by search term (if entered)
      if (searchTerm.trim() !== '') {
        filtered = filtered.filter((product) =>
          product.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }

      // Update the state with the filtered products
      setFilteredProducts(filtered);
    };

    filterProducts();
  }, [selectedCategory, searchTerm]); // Dependencies: Re-run whenever these change

  return (
    <>
   
  <Helmet>
  
    {/* Primary Meta Tags */}
    <title>Products | BJS Healthcare | Pharmaceutical Solutions</title>
    <meta 
      name="description" 
      content="Explore the wide range of pharmaceutical products offered by BJS Healthcare. Our portfolio includes Betalactam, Nonbetalactam, Softgel Capsules, Liquids, Syrups, and Injectable formulations, ensuring quality and innovation in every solution." 
    />
    <meta 
      name="keywords" 
      content="Pharmaceutical Products, BJS Healthcare Products, Betalactam, Nonbetalactam, Softgel Capsules, Liquids, Syrups, Injectable, Pharmaceutical Solutions" 
    />
    <meta name="author" content="BJS Healthcare Pvt Ltd" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />

    {/* Open Graph / Facebook */}
    <meta property="og:title" content="Products | BJS Healthcare | Pharmaceutical Solutions" />
    <meta 
      property="og:description" 
      content="Discover our extensive range of pharmaceutical products at BJS Healthcare. From Betalactam to Injectable formulations, we deliver quality and innovation." 
    />
    <meta property="og:image" content="https://example.com/path-to-products-hero-image.jpg" />
    <meta property="og:url" content="https://bjshealthcare.com/products" />
    <meta property="og:type" content="website" />

    {/* Twitter */}
    <meta name="twitter:title" content="Products | BJS Healthcare | Pharmaceutical Solutions" />
    <meta 
      name="twitter:description" 
      content="BJS Healthcare offers a wide range of pharmaceutical products, including Betalactam, Nonbetalactam, Softgel Capsules, Liquids, Syrups, and Injectables. Explore now!" 
    />
    <meta name="twitter:image" content="https://example.com/path-to-products-hero-image.jpg" />
    <meta name="twitter:card" content="summary_large_image" />

    {/* Additional Meta Tags */}
    <meta property="og:site_name" content="BJS Healthcare Pvt Ltd" />
    <link rel="canonical" href="https://bjshealthcare.com/products" />
    <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Organization",
            "name": "BJS Healthcare Pvt Ltd",
            "url": "https://bjshealthcare.in",
            "logo": "https://bjshealthcare.in",
            "description": "BJS Healthcare Pvt Ltd is an ISO 9001-2015 and WHO-GMP certified pharmaceutical manufacturer, offering over 1200 high-quality products.",
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "5",
              "reviewCount": "9521"
            },
            "review": {
              "@type": "Review",
              "reviewRating": {
                "@type": "Rating",
                "ratingValue": "5",
                "bestRating": "5"
              },
              "author": {
                "@type": "Person",
                "name": "Satisfied Customer"
              }
            }
          })}
        </script>
  </Helmet>
    <div className={styles.productsContainer}>
      {/* Hero Section */}
      <div className={styles.heroSection}>
        <video autoPlay loop muted className={styles.heroVideo}>
          <source src='https://yeskondomedia.com/Videos/about_hero.mp4' type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className={styles.heroOverlay}></div> {/* Overlay added */}
        <div className={styles.heroContent}>
          <h1>Our Products</h1>
          <p>Discover our wide range of pharmaceutical solutions</p>
        </div>
      </div>

      {/* Product Section */}
      <div className={styles.productSection}>
        {/* Category Filter */}
        <div className={styles.categoryFilter}>
          {categories.map((category, index) => (
            <button
              key={index}
              className={`${styles.categoryButton} ${selectedCategory === category ? styles.active : ''
                }`}
              onClick={() => setSelectedCategory(category)}
            >
              {category}
            </button>
          ))}
        </div>

        {/* Search Bar */}
        <div className={styles.searchBar}>
          <input
            type="text"
            placeholder="Search products..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        {/* Product Grid */}
        <div className={styles.productGrid}>
          {filteredProducts.length > 0 ? (
            filteredProducts.map((product) => (
              <div key={product.id} className={styles.productCard}>
                <h4>{product.name}</h4>
                <p>Drug Class: {product.category}</p>
                <p>Dosage Form: {product.subcategory}</p>
              </div>
            ))
          ) : (
            <p className={styles.noProducts}>No products found</p>
          )}
        </div>
      </div>
    </div>
    </>
  );
};

export default Products;
