import React, { useState } from 'react';
import styles from './Contact.module.css';
import { Helmet } from 'react-helmet';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log('Form submitted:', formData);
  };

  return (
    <>
      <Helmet>
      
        {/* Primary Meta Tags */}
        <title>Contact Us | BJS Healthcare | Get in Touch</title>
        <meta
          name="description"
          content="Contact BJS Healthcare today! Reach out for inquiries about our pharmaceutical manufacturing services. Visit us in Pune or call us at +91 9833100676. We'd love to hear from you."
        />
        <meta
          name="keywords"
          content="Contact BJS Healthcare, BJS Healthcare Pune, Pharmaceutical Manufacturing Inquiries, Get in Touch, Call BJS Healthcare, Pharmaceutical Company Contact Details"
        />
        <meta name="author" content="BJS Healthcare Pvt Ltd" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />

        {/* Open Graph / Facebook */}
        <meta property="og:title" content="Contact Us | BJS Healthcare | Get in Touch" />
        <meta
          property="og:description"
          content="We are here to answer your questions and discuss your needs. Visit our Pune office or call us at +91 9833100676. Contact BJS Healthcare today!"
        />
        <meta property="og:image" content="https://example.com/path-to-contact-hero-image.jpg" />
        <meta property="og:url" content="https://bjshealthcare.com/contact" />
        <meta property="og:type" content="website" />

        {/* Twitter */}
        <meta name="twitter:title" content="Contact Us | BJS Healthcare | Get in Touch" />
        <meta
          name="twitter:description"
          content="Have questions? Contact BJS Healthcare for inquiries about our pharmaceutical solutions. Call us at +91 9833100676 or visit our Pune office."
        />
        <meta name="twitter:image" content="https://example.com/path-to-contact-hero-image.jpg" />
        <meta name="twitter:card" content="summary_large_image" />

        {/* Additional Meta Tags */}
        <meta property="og:site_name" content="BJS Healthcare Pvt Ltd" />
        <link rel="canonical" href="https://bjshealthcare.com/contact" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Organization",
            "name": "BJS Healthcare Pvt Ltd",
            "url": "https://bjshealthcare.in",
            "logo": "https://bjshealthcare.in",
            "description": "BJS Healthcare Pvt Ltd is an ISO 9001-2015 and WHO-GMP certified pharmaceutical manufacturer, offering over 1200 high-quality products.",
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "5",
              "reviewCount": "9521"
            },
            "review": {
              "@type": "Review",
              "reviewRating": {
                "@type": "Rating",
                "ratingValue": "5",
                "bestRating": "5"
              },
              "author": {
                "@type": "Person",
                "name": "Satisfied Customer"
              }
            }
          })}
        </script>
      </Helmet>
      <div className={styles.contact}>
        <div className={styles.heroSection}>
          <h1>Get in Touch</h1>
          <p>We'd love to hear from you. Let's start a conversation!</p>
        </div>

        <div className={styles.contactContent}>
          <div className={styles.contactInfo}>
            <div className={styles.infoCard}>
              <i className={`${styles.icon} ${styles.locationIcon}`}></i>
              <h3>Our Location</h3>
              <p>Office No: 206 VTP Trade Park, Katraj Hadapsar Road, Undri, Pune</p>
            </div>
            <div className={styles.infoCard}>
              <i className={`${styles.icon} ${styles.phoneIcon}`}></i>
              <h3>Phone Number</h3>
              <p>Mobile: +91 9833100676</p>
            </div>
            <div className={styles.infoCard}>
              <i className={`${styles.icon} ${styles.emailIcon}`}></i>
              <h3>Email Address</h3>
              <p>info@bjshealthcare.com</p>
            </div>
          </div>

          <form className={styles.contactForm} onSubmit={handleSubmit}>
            <h2>Send us a Message</h2>
            <div className={styles.formGroup}>
              <input
                type="text"
                name="name"
                placeholder="Your Name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className={styles.formGroup}>
              <input
                type="email"
                name="email"
                placeholder="Your Email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className={styles.formGroup}>
              <input
                type="text"
                name="subject"
                placeholder="Subject"
                value={formData.subject}
                onChange={handleChange}
                required
              />
            </div>
            <div className={styles.formGroup}>
              <textarea
                name="message"
                placeholder="Your Message"
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
            </div>
            <button type="submit" className={styles.submitBtn}>Send Message</button>
          </form>
        </div>

        <div className={styles.mapSection}>
          <h2>Find Us on the Map</h2>
          <div className={styles.mapContainer}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3784.7560925697135!2d73.91026347465055!3d18.449380271346087!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2eb47aceaffc3%3A0xb5f50ebfee8fc0a8!2sBJS%20HEALTHCARE%20PVT%20LTD!5e0!3m2!1sen!2sin!4v1732698930832!5m2!1sen!2sin"
              width="100%"
              height="450"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>

        <div className={styles.ctaSection}>
          <h2>Ready to Get Started?</h2>
          <p>Our team is here to answer your questions and discuss your needs.</p>
          <a href="tel:+91 9833100676" className={styles.ctaButton}>Call Us Now</a>
        </div>
      </div>
    </>
  );
};

export default Contact;